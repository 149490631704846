import styled, { css } from 'styled-components';
const BoldFont = 'Rubik-Medium';

const HeaderContainer = styled.div`
  width: 100%;
  padding: ${({ isLongContainer }) => isLongContainer ? '21px 17px 15px' : '17px 17px 11px'};
  border-bottom:  ${({ borderBottom }) => borderBottom ? 'none' : '1px solid #ededed'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: ${({setColor, background}) => background?'transparent':setColor ? "#005C87" : 'white'};
  // background: #FFFFFF;
  border-top-left-radius: ${({setColor}) => setColor ? '5px' : '0'};
  border-top-right-radius: ${({setColor}) => setColor ? '5px' : '0'};
  padding: ${({setColor, padding}) => padding?'10px 17px 10px':setColor ? '10px 17px 10px' : '10px 10px 5px'};
  border-bottom-left-radius : ${({ modalHeader}) => modalHeader ? '5px' : '0'};
  border-bottom-right-radius: ${({ modalHeader}) =>  modalHeader ? '5px' : '0'};
  // min-height: 56px;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:nth-child(2) {
      > span {
        font-size: 16px;
        color: ${({setColor}) => setColor ? '#fff' : 'rgb(153,153,153)'};
        > h5 {
          padding-right: ${({setColor}) => setColor ? '10px' : '0px'};
          margin: 0;
        }
      }
    }
  }

  ${({ textAlign }) => textAlign && css`
    height: 52px;
    > div {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
    }
  `}

  @media (max-width: 500px) {
    padding: ${({ isLongContainer }) => isLongContainer ? '21px 10 15px' : '17px 10px 11px'};
  }

  ${({ noPadding }) => noPadding && css`
    padding: 21px 0 15px;

    @media (max-width: 500px) {
      padding: 21px 0 15px;
    }
  `}
  .image_class {
    > img {
       width: auto;
       height: 25px;
       cursor: pointer;
    }
  }
`;

const HeaderImage = styled.img`
  height: 24px;
  margin-right: 9px;
`;

const HeaderTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  text-transform: uppercase;
  line-height: 18px;
  text-align: left;

  > span {
    color: #59bbd9;
  }

  > img {
    width: 19px;
    height: 19px;
    margin-left: 5px;
  }
`;
export { HeaderContainer, HeaderImage, HeaderTitle};