import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VerticalBarChart from "../VerticalBarChart";
import {Flexbox} from "../Experience/CultureSurvey/styles";
import { isEmpty } from 'lodash';


class HighChatV2 extends Component {
  
  render() {
    const {data, xAxisLabels} = this.props;
    return(
      isEmpty(data) && data.length === 0 ? 'No top wellness goals' :
        <Flexbox>
          <VerticalBarChart
            id={`chartData-wellness`}
            seriesData={data}
            xAxisLabel={xAxisLabels}
            yAxisVisible={false}
            fillColor={1}
            cultureSurvey={1}
            wellnessGoals={1}
          />
        </Flexbox>
    ) 
    
  }
}

HighChatV2.propTypes = {
  data: PropTypes.array.isRequired,
  container: PropTypes.string.isRequired,
  legendWidth: PropTypes.number,
  xAxisLabels: PropTypes.array,
};

export default  HighChatV2;
