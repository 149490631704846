import styled, {css} from 'styled-components'

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const OverlayDashboard = styled.div`
  opacity: 1;

  ${({showOverlay}) => showOverlay && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

const DashboardLayout = styled.div`
  color: rgb(62,78,104);
  font-size: 30px;
  @media (max-width: 760px) {
    text-align: center;
    font-size: 20px;
  }
`;

const RecognitionSection = styled.div`
    width: 100%;
    padding: 25px;
   .recognition-wrapper{
      max-width: 1246px;
      margin: auto;
   }
`;

const HeaderWrapper = styled.div`
  float: left;
  width: 100%;
  padding: ${(props) => props.padding ? '25px 37px 25px 32px' : '30px 0 0 0'};
  // -webkit-box-shadow: inset 3px 5.196px 5px rgba(0,0,0,0.08);
  // box-shadow: inset 3px 5.196px 5px rgba(0,0,0,0.08);
  @media (max-width: 500px) {
    padding: 30px 10px 20px;
  }
`;

const CardDashboard = styled.div`
  float: left;
  background-color: #ededed;
  width: 100%;
  display: block;
  @media (max-width: 1275px) and (min-width: 501px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 620px) {
    padding: 0 10px 20px;
  }
`;

const CardContainer = styled.div`
  float: left;
  min-height: 120px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  width: calc((100% - 54px)/4);
  display: inline-block;
  margin-right: 18px;
  background: #fff;
  padding: 0 10px;
  border-radius: 6px;
  &:last-child {
    margin-right: 0;
  }
  > p {
    color: #9EA0A5;
    font-family: ${BoldFont};
    font-size: 16px;
    font-weight: 500;
    padding-left: 7px;
    float: left;
    margin-bottom: -4px;
  }
  
  > div:nth-child(2){
    padding: 0
  }
  
  @media (max-width: 1275px) and (min-width: 501px) {
    width: calc((100% - 18px)/2);
    margin: 10px 0px 10px 0;
    padding: 0 10px;

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  @media (max-width: 620px) {
    width: 100%;
    margin: 10px 0;
    padding: 0 10px;
  }
`;

const Row1 = styled.div`
  float: left;
  width: 100%
  padding: 10px 0 0 0;
  min-height: 67px;
  
  & > p {
    font-size: 12px;
    max-width: 195px;
    float: left;
    margin: 0;
    text-transform: uppercase;
    color: ${({ active }) => active ? '#FFFFFF' : '#6F767E'};
    padding: 13px 0 0 7px;
    line-height: 17px;
    font-family: ${BoldFont};
    font-weight: 700;
    @media (max-width: 1275px) {
     max-width: none;
    }
  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }
  
  img {
    width: 75px;
    height: 50px;
    float: right;
  }
`;

const Row2 = styled.div`
  float: left;
  width: 100%;
  padding: 0px 4px 10px;
  & > p {
    font-size: 12px;
    max-width: 205px;
    float: left;
    margin: 0;
    text-transform: uppercase;
    color: ${({ active }) => active ? '#FFFFFF' : '#6F767E'};
    padding: 13px 0 0 7px;
    line-height: 17px;
    font-family: ${BoldFont};
    font-weight: 700;
    @media (max-width: 1275px) {
     max-width: none;
    }
  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }
  
  img {
    width: ${({ circularImage }) => circularImage ? '50px' : '75px'};
    height: 50px;
    float: right;
    filter: ${({active}) => active ? 'invert(100%) sepia(79%) saturate(60%) hue-rotate(285deg) brightness(189%) contrast(246%)' : 'none'}
  }
`;

const Row3 = styled.div`
  float: left;
  width: 100%
  display: block;
  padding: 10px 0;
  
  & > div {
    font-size: 18px;
    float: left;
    margin: 0;
    color: rgba(40, 44, 55, 0.5);
    padding: 0 0 0 7px;
    letter-spacing: 1px;
    font-family: ${MediumFont};
    & > span:nth-child(2) {
     padding: 0 0 0 7px;
    }
  }
  & > div:nth-child(2) {
    float: right;
    color: rgba(40, 44, 55, 1);
    font-family: ${BoldFont}
  }
  
  img {
    width: 27px;
    height: 25px;
    float: left;
  }
`;

const Layout = styled.div`
  width: 100%;
  padding: 5px 37px 30px 32px;
  font-family: ${RegularFont};
  margin-bottom: 100px;
  float: left;

  @media (max-width: 500px) {
    padding: 30px 10px 30px;
  }
`;

const Section = styled.div`
  box-shadow: 0 0 21px rgba(0,0,0,0.08);
  background-color: #ffffff;
  display: inline-block;
  float: left;
  position: relative;

  @media (max-width: 1175px) {
    width: 100%;
  }
`;

const MainSection = styled(Section)`
  width: calc((100% - 18px) / 2);
  margin-right: 18px;
  background-color: white;
  border-radius: 5px
  box-shadow: rgba(0,0,0,0.08);
  @media (max-width: 1175px) {
    margin-right: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  min-height: 59px;
  border-bottom: 1px solid #ededed;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0px 17px 0px;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
`;

const HeaderImage = styled.img`
  height: 27px;
  margin-right: 9px;
`;

const HeaderTitle = styled.div`
  font-size: 15px;
  letter-spacing: 0px;
  color: #282c37;;
  font-family: ${MediumFont};
  line-height: 18px;
  text-align: left;

  > span {
    color: #59bbd9;
  }

  > img {
    width: 19px;
    height: 19px;
    margin-left: 5px;
  }
`;

const LegendBox = styled.p`
  width: 24px;
  height: 8px;
  border-radius: 6px;
  display: inline-block;
  background-color: ${({ color }) => color};
  margin: 0 15px 0 0;
`;

const LegendText = styled.div`
  font-size: 12px !important;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

const   GraphContainer = styled.div`
  width: 100%;
  height: calc(100% - 52px);
`;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  float: left;
  min-height: ${({ longContainer }) => longContainer ? '418px' : '361px'};
  &:first-child {
    margin-top: 0;
  }
  & > div:last-child {
    margin-right: 0px;
  }

  @media (max-width: 1175px) {
    height: auto;
    & > div:last-child {
      margin-top: 10px;
    }
  }
`;

const ActivityBar = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 22px;
    border-radius: 20px;
    background-color: ${(props) => props.backgroundFill ? props.backgroundFill : 'rgba(86, 192, 36, 0.3)'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${(props) => props.margin ? props.margin : '5px 0px'}
`;

const ActivityBarFill = styled.div`
    width: ${(props)=> props.width ? props.width : '0%'};
    height: 100%;
    border-radius: 20px;
    background-color: ${(props) => props.fillColor ? props.fillColor : '#000'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px 0px 12px;
    position: absolute;
    top: 0px;
    > span {
     cursor : pointer;
     color: #fff;
     font-size: 11px;
     font-family: ${BoldFont};
     display: inline-block;
   }
`;

const ActivityContainer = styled.div`
    width: ${({width}) => width ? width : '100%'};
    font-size: 13px;
    margin: ${({bloodPressure}) => bloodPressure ? bloodPressure : '15px 0px'};
    text-align: left;
    .chartTitle {
        color: #000;
        font-family: ${BoldFont}
        display: inline-block;
        margin-right: 5px;
    }
    .sliderTitle {
      font-family: ${BoldFont};
      font-size: 14px;
      color: #282C37;
    }
    .chartSubTitle {
        color: #C9CACC;
        font-family: ${MediumFont};
        display: inline-block;
    }
    
    @media (max-width: 1175px) and (min-width:1024px) {
      width: ${({width}) => width ? '85%'  : '100%'};
    }
    
    @media (max-width: 1023px) and (min-width:950px) {
      width: ${({width}) => width ? '80%'  : '100%'};
    }
    
    @media (max-width: 949px) and (min-width:850px) {
      width: ${({width}) => width ? '85%'  : '100%'};
    }
    @media (max-width: 500px) {
       width: ${({width}) => width ? '100%'  : '100%'};
    }
`;
const WellbeingContainer = styled.div`
    display: flex;
    width: 100%
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 500px) {
      display: block;
    }
`;
const IndicatorContainer = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
    margin: 15px 0 2px 0;
    & > span {
      font-size: 13px;
      color: #282C37;
      font-family: ${BoldFont};
      display: inline-block;
      margin-left: 5px;
    }
    
    @media (max-width: 1175px) and (min-width:1024px) {
      width: 15%;
    }
    
    @media (max-width: 1023px) and (min-width: 950px) {
      width: 20%
    }
    @media (max-width: 949px) and (min-width: 850px) {
      width: 15%;
    }
    @media (max-width: 500px) {
       width: 100%
    }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({backgroundFill}) => backgroundFill ? backgroundFill : 'blue'};
  
`;

const StyledImage = styled.img`
  width: ${({width}) => width ? width : '13px'};
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: ${({height}) => height ? height : '56px'}
    padding: 6px 17px 6px;
    background-color: ${({bgColor}) => bgColor ? bgColor : 'none'};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : 'none'};
    & > img {
      float: left;
    }
    & > div {
      padding-right: 30px;
      float: right;
      display: flex;
      align-items: center; 
      > a {
        display: block;
        font-size: 14px;
        color: rgb(51,51,51); 
        font-family: ${BoldFont};
       
      } 
      > img {
          width: 8px;
          margin-left: 10px;
      } 
      > i {
          font-size: 25px;
          color: #26262B;
          line-height: 0;
      }  
    }
`;

const Title = styled.p`
   font-size: ${({fontSize}) => fontSize ? fontSize : '14px'};
   letter-spacing: 0.8px;
   font-family: ${MediumFont};
   font-weight: ${({fontWeight}) => fontWeight ? fontWeight : '700'};
   text-transform: ${({textTransform}) => textTransform ? 'uppercase' : 'none'};
   line-height: 18px;
   text-align: left;
   float: left;
   margin: 0;
   color: ${({textColor}) => textColor ? textColor : 'none'};
   max-width: ${({maxWidth}) => maxWidth ? maxWidth : 'none'};
   padding: ${({padding}) => padding ? padding : '0'};
   
   @media (max-width: 1200px) and (min-width: 1024px) {
    max-width: none;
   }
   
   @media (max-width: 1023px) and (min-width: 700px) {
    max-width: 600px;
   }   
`;
const CardSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 365px;
  margin-top: 16px;
  flex-wrap: wrap;
`;
const SliderLabel = styled.span`
 color: ${({textColor}) => textColor ? textColor : 'none'};
 font-size: 11px;
 font-family: ${MediumFont};
 z-index: 1;
 padding: 0px 20px;
 float:right;
 display: inline-block;
 margin-left: auto
`;
const SubTitleContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding: 6px 17px 6px;
    & > img {
      float: left;
    }
    & > div {
      padding-right: 30px;
      float: right;
      display: flex;
      align-items: center; 
      > a {
        display: block;
        font-size: 14px;
        color: rgb(51,51,51); 
        font-family: ${BoldFont};
       
      } 
      > img {
          width: 8px;
          margin-left: 10px;
      } 
      > i {
          font-size: 25px;
          color: #26262B;
          line-height: 0;
      }  
    }
`;

const ArrowContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   float: right;
   width: 25px;
   height: 25px;
   border-radius: 50%;
   margin: 10px 0 0 0;
   background-color: ${(props) => props.transform === 0 ? '#FAB331' : props.transform === 1 ? '#94BF3E' : '#FF3F19'};
   & > img {
    width: 9px;
    transform: ${(props) => props.transform === 0 ? 'rotate(270deg)' : props.transform === 1 ? 'rotate(180deg)' : 'rotate(360deg)'};
   }
`;
export { OverlayDashboard, DashboardLayout, HeaderWrapper, CardDashboard, CardContainer, Row1, Row2, Row3, Layout, MainSection,
  HeaderContainer, HeaderImage, HeaderTitle, LegendBox, LegendText, GraphContainer, Container,
  ActivityBar, ActivityBarFill, ActivityContainer,IndicatorContainer, WellbeingContainer, ImageContainer, StyledImage, TitleContainer, Title, CardSection, SliderLabel,SubTitleContainer, ArrowContainer, RecognitionSection}